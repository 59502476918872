<!--suppress HtmlUnknownTarget -->
<template>
  <div>
    <div v-if="loading" class="large-font text-bold container p-tb-2">loading</div>
    <div v-else>
      <div v-if="item">
        <div class="product">
          <div class="container-fluid p-tb-3">
            <div class="container">
              <div class="row start-lg">
                <div class="col-lg-4 p-xs-25px col-xs-12 col-sm-4">
                  <div class="product-image p-25px bordered-light rounded-5">
                    <VueSlickCarousel v-bind="sliderSettings" v-if="images.length">
                      <img v-for="(img, i) in images" :key="i" :src="img" :alt="`img-${i}`" />
                    </VueSlickCarousel>
                    <img v-else src="/img/logo-small.svg" alt="No image placeholder" style="width: 100%" />
                  </div>
                </div>
                <div class="col-lg-8 col-sm-8">
                  <div class="p-25px">
                    <div class="block">
                      <h1 class="p-tb-05">{{ item.seoData.heading || item.title }}</h1>
                      <div class="p-tb-05">
                        <span class="tag" v-for="(type, i) in item.types" :key="i">{{ type.name }}</span>
                      </div>
                      <div class="w-75 p-tb-05 text-color-light" v-html="item.description" />
                      <div class="row">
                        <div class="col-lg-6" v-if="item.company">
                          <p class="text-bold text-color-light-green">Company</p>
                          <p>{{ item.companyName }}</p>
                        </div>
                        <div class="col-lg-6" v-if="item.brand">
                          <p class="text-bold text-color-light-green">Brand</p>
                          <p>{{ item.brandTitle }}</p>
                        </div>
                        <div class="col-lg-6" v-if="item.minimalQty">
                          <p class="text-bold text-color-light-green">Minimal quantity for order</p>
                          <p>{{ item.minimalQty }}</p>
                        </div>
                        <div class="col-lg-12 p-tb-05">
                          <p class="text-bold uppercase text-color-light-green">
                            Under my brand:
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row p-tb-05">
                      <div :class="`col-xs-12 xs-mt-15 col-lg-${!showAddToCart ? 6 : 4}`">
                        <div class="m-r-1 rounded-5">
                          <div class="button bg-gray">
                            <div v-if="item.price">
                              <span>from</span>
                              <span class="text-medium large-font p-5px">${{ item.price.toFixed(2) }}*</span>
                              <span>per item</span>
                            </div>
                            <div v-else>
                              <span class="text-medium large-font p-5px">get a quote*</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="isInCart">
                        <div class="col-lg-6 col-xs-12 xs-mt-15">
                          <div class="m-r-1 rounded-5">
                            <router-link
                              to="/cart"
                              class="text-medium medium-font button uppercase text-color-white bg-light-green"
                            >
                              Checkout your cart
                            </router-link>
                          </div>
                        </div>
                      </template>
                      <div v-if="showAddToCart" class="col-lg-4 col-xs-12 xs-mt-15">
                        <div class="m-r-1 rounded-5">
                          <template>
                            <div
                              @click="addToCart"
                              class="text-medium medium-font button uppercase text-color-white bg-light-green"
                              style="background-color: #fbac45"
                            >
                              Add to cart
                            </div>
                          </template>
                        </div>
                      </div>
                      <div v-if="!isInCart && isLoggedIn" class="col-lg-4 col-xs-12 xs-mt-15">
                        <div class="m-r-1 rounded-5">
                          <router-link
                            :to="`/order/${slug}`"
                            class="text-medium medium-font button uppercase text-color-white bg-light-green"
                            style="background-color: #fbac45"
                          >
                            Order now
                          </router-link>
                        </div>
                      </div>
                      <div v-if="!isLoggedIn" class="col-lg-6 col-xs-12 xs-mt-15">
                        <p class="text-center ">
                          <span class="text-color-light-green text-bold large-font">* </span>
                          Log in or register to make order
                        </p>
                      </div>
                      <div v-if="item.customizable" class="col-lg-6 col-xs-12 mt-15">
                        <div class="m-r-1 rounded-5">
                          <template>
                            <router-link
                              :to="`/constructor/${item.slug}`"
                              class="text-medium medium-font button uppercase text-color-white bg-light-green"
                            >
                              Build your own design
                            </router-link>
                          </template>
                        </div>
                      </div>
                    </div>
                    <p class="text-color-light p-tb-05">
                      <span class="text-color-light-green text-bold large-font">* </span>
                      <span v-if="item.price">
                        The price changes depending on the details of your order
                      </span>
                      <span v-else>
                        Ask our support for a quote
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-desc">
          <div class="container-fluid bg-gray">
            <div class="container">
              <div class="tab-togglers">
                <template v-for="(tab, i) in availableTabs">
                  <span v-if="activeTab === tab.id" class="text-bold active" :key="i">{{ tab.title }}</span>
                  <span v-else class="text-bold" :key="i" @click="currentTab = tab.id">{{ tab.title }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="container p-tb-2 p-b-4">
            <div class="p-10px">
              <div class="row specs">
                <div class="col-lg-6 col-xs-12" v-if="activeTab === 'specification'" v-html="item.specification" />
                <div class="col-lg-6 col-xs-12" v-if="activeTab === 'features'">
                  <div class="row" v-for="(feature, i) in item.features" :key="i">
                    <div class="col-lg-7 spec col-xs-7">
                      <p class="text-medium">{{ feature.name }}</p>
                    </div>
                    <div class="col-lg-5 spec col-xs-5">
                      <p>{{ feature.value }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xs-12" v-if="activeTab === 'components'">
                  <div class="row" v-for="(component, i) in components" :key="i">
                    <div class="col-lg-7 col-xs-7 spec">
                      <p class="text-medium">{{ component.name }}</p>
                    </div>
                    <div class="col-lg-5 col-xs-7 spec">
                      <p>{{ component.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="large-font text-bold p-25px text-center">Not found</div>
    </div>
  </div>
</template>

<script>
import Image from 'Models/Image';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { format, setCanonical, updateMeta } from 'Lib/Utils';
import Categories from 'Lib/enums/Categories';
import { MICRO_PRODUCT, removeMicroMarkup, setMicroMarkup } from 'Lib/MicroMarkup';

const tabs = [
  {
    id: 'features',
    title: 'Features',
  },
  {
    id: 'specification',
    title: 'Specifications',
  },
];

export default {
  name: 'Product',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      tabs,
      item: null,
      loading: true,
      currentTab: 'features',
      components: [],
      isInCart: false,
      sliderSettings: {
        dots: true,
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
      wrapperHeight: 0,
    };
  },
  watch: {
    slug() {
      this.loadItem(this.slug);
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    slug() {
      return this.$route.params.slug;
    },
    availableTabs() {
      if (!this.item) {
        return [];
      }
      return tabs.filter(el => this.item[el.id] && this.item[el.id].length);
    },
    activeTab() {
      if (this.availableTabs.length > 0) {
        return this.availableTabs.some(el => el.id === this.currentTab) ? this.currentTab : this.availableTabs[0].id;
      }
      return this.currentTab;
    },
    images() {
      return this.item.images.map(item => {
        return new Image(item).toString();
      });
    },
    showAddToCart() {
      return !this.isInCart && this.isLoggedIn;
    },
    metaTemplates() {
      const { metaInfo } = this.$route.meta;
      return metaInfo.templates;
    },
    title() {
      return this?.item?.title || '';
    },
  },
  methods: {
    addToCart() {
      this.$store.dispatch('cartAdd', { id: this.item.id, qty: this.item.minimalQty });
    },
    loadItem(slug) {
      this.loading = true;
      this.$api
        .getProductBySlug(slug, !this.isLoggedIn)
        .then(model => {
          this.$set(this.$data, 'item', model);
          this.updateMeta(model);
          this.setBreadcrumbs();
          this.setMicroMarkup();
          this.loading = false;
          this.unsubCart = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'updateCart') {
              this.isInCart = state.cart.has(+model.id);
            }
          });
          this.isInCart = this.$store.state.cart.has(+model.id);
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    onError() {
      this.$data.loading = false;
    },
    updateMeta(product) {
      const { seoData } = product;
      if (seoData.title || seoData.description) {
        updateMeta(seoData);
      } else {
        const { title, description } = this.metaTemplates || {};
        if (title) {
          updateMeta({ title: format(title, product) });
        }
        if (description) {
          updateMeta({ title: format(description, product) });
        }
      }
    },
    setBreadcrumbs() {
      const breadcrumbs = [];
      const [cat, subcat] = this.$route.path.substr(1).split('/');
      const category = Categories.find(el => el.slug === cat);
      if (category) {
        breadcrumbs.push({ to: `/${cat}`, title: category.title });
        const subcategory = category.filters.categories.find(el => el.slug === subcat);
        setCanonical(`/${cat}/${this.slug}`);
        if (subcategory) {
          breadcrumbs.push({ to: `/${cat}/${subcat}`, title: subcategory.name });
        }
      }
      breadcrumbs.push({ to: this.$route.path, title: this.title });
      this.$store.dispatch('setBreadcrumbs', breadcrumbs);
    },
    setMicroMarkup() {
      if (this.item) {
        setMicroMarkup(MICRO_PRODUCT, { product: this.item, url: this.$route.path });
      }
    },
  },
  created() {
    this.loadItem(this.slug);
  },
  beforeMount() {
    this.setBreadcrumbs();
    this.setMicroMarkup();
  },
  beforeDestroy() {
    this?.unsubCart();
    removeMicroMarkup(MICRO_PRODUCT);
  },
};
</script>
